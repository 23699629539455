import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Rise Scaffold',
    url: '/rise-scaffold',
  },
]

const RiseScaffod = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Rise Scaffold"
      description="R&D Rise Scaffold"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Rise Scaffold"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Rise Scaffold" />
          <CaseStudyContent
            heading=""
            paragraph='"Before dealing with RDA, my understanding of the R&D scheme was very limited; we had thought it was for medical industries. But having been through the process with them, we are now finding that we are looking at more innovative solutions to projects as we are aware that there could be financial support for applying new methods and designs. '
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <p>
                  Our dealings with them have exceeded our expectations and
                  everyone we have dealt with seems professional; we’d certainly
                  recommend them."
                </p>

                <h6>Robert Howlett, Rise Scaffold</h6>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default RiseScaffod
